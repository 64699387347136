import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import MachineInfo from '../components/MachineInfo'; 
import OtherService from '../components/service/OtherService'; 
import Header from '../components/Header';


const OtherServicePage = () => {
  return (
    <div className="container">
      <Header/>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <OtherService />
        </div>
      </div>
    </div>
  );
};

export default OtherServicePage;
