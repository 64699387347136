import React from 'react';
import { Link } from 'react-router-dom';

const GenServiceButton = () => {  
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="form-container">
            <p className="text-center">For more general requests, please select one of the below:</p>
            <form>
              <Link to="/service-request-general">
                <button className="btn btn-block">
                I do not have / know the Machine ID or request is location specific
                </button>
              </Link>
              <p></p>
              <Link to="/service-request-other">
                <button className="btn btn-block">
                For proposals, inquiries, and all other request
                </button>
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenServiceButton;
