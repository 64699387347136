import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask'
import {SuccessComponent, ErrorComponent} from '../ErrorSuccess'


const OtherService = () => {
  
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phoneType, setPhoneType] = useState('');
  const [phone, setPhone] = useState('');
  const [topic, setTopic] = useState('');
  const [problemDescription, setProblemDescription] = useState('');
  const [formStatus, setFormStatus] = useState(null);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if the email is valid before proceeding
    if (!emailRegex.test(email) && email !== '') {
      alert('Please enter a valid email address.');
      return;
    }

    // Check if the phone number is valid before proceeding
    if (!phoneRegex.test(phone) && phone !== '') {
      alert('Please enter a valid phone number in the format (___) ___-____.');
      return;
    }

    const formData = {
      service_type: "Other",
      firstName,
      lastName,
      address,
      phoneType,
      phone,
      email,
      topic,
      problemDescription
    };

    try {
      const response = await fetch('/api/gen-service', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData); // Use responseData received from the server
        // Handle success, maybe show a success message
        setFormStatus('success');
      } else {
        throw new Error('Failed to submit the form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error, show an error message to the user
      setFormStatus('error');
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="form-container">
          <h2 className="text-center" style={{ marginBottom: '0'}}>Submit Service Ticket</h2>
          <p className="text-center" style={{ marginBottom: '0', color: '#ccc' }}><small>*required field</small></p>
            {formStatus === 'success' ? (
              <SuccessComponent />
            ) : formStatus === 'error' ? (
              <ErrorComponent />
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="inputFirstName">First Name<span style={{ color: '#ccc' }}>*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputFirstName"
                    placeholder="Enter first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required // Required field
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="inputLastName">Last Name<span style={{ color: '#ccc' }}>*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputLastName"
                    placeholder="Enter last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required // Required field
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="inputAddress">Address</label>
                  <textarea
                    className="form-control"
                    id="inputAddress"
                    rows="1"
                    placeholder="Enter address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="inputEmail">Email<span style={{ color: '#ccc' }}>*</span></label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required // Required field
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="inputPhoneType">Phone Type<span style={{ color: '#ccc' }}>*</span></label>
                  <select
                    className="form-control"
                    id="inputPhoneType"
                    value={phoneType}
                    onChange={(e) => setPhoneType(e.target.value)}
                    required // Required field
                  >
                    <option value="" disabled selected>Select phone type</option>
                    <option value="mobile">Mobile</option>
                    <option value="home">Home</option>
                    <option value="work">Work</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="inputPhone">Phone<span style={{ color: '#ccc' }}>*</span></label>
                  <InputMask
                    mask="(999) 999-9999"
                    className="form-control"
                    id="inputPhone"
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required // Required field
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="inputTopic">Topic</label>
                  <textarea
                    className="form-control"
                    id="inputTopic"
                    rows="1"
                    placeholder="Enter topic (e.g. proposal, survey, quote)"
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="inputProblemDescription">Problem Description<span style={{ color: '#ccc' }}>*</span></label>
                  <textarea
                    className="form-control"
                    id="inputProblemDescription"
                    rows="3"
                    placeholder="Enter problem description"
                    value={problemDescription}
                    onChange={(e) => setProblemDescription(e.target.value)}
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-block">
                  Submit
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherService;
