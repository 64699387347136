import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask'
import {SuccessComponent, ErrorComponent} from '../ErrorSuccess'
import Autosuggest from 'react-autosuggest';
import Refund from '../Refund';


const GenService = () => {

  const [locationList, setLocationList] = useState([]);
  const [machineTypeList, setMachineTypeList] = useState([]);
  const [errorCodeList, setErrorCodeList] = useState([]);
  const [problemTypeList, setProblemTypeList] = useState([]);

  const [location, setLocation] = useState('');
  const [locationDescription, setLocationDescription] = useState('');
  const [machineType, setMachineType] = useState('');
  const [machineId, setMachineId] = useState('');
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phoneType, setPhoneType] = useState('');
  const [phone, setPhone] = useState('');
  const [refundPhone, setRefundPhone] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const [problemType, setProblemType] = useState('');
  const [problemDescription, setProblemDescription] = useState('');
  const [formStatus, setFormStatus] = useState(null);

  const [refundOption, setRefundOption] = useState('no');
  const [refundAmount, setRefundAmount] = useState('');
  const [lostMoneyMethod, setLostMoneyMethod] = useState('');
  const [serialNumber, setSerialNumber] = useState('');

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('/api/form-info');
        if (response.ok) {
          const data = await response.json();
          // Extract 'error codes' and 'problem types' arrays from the API response
          const locations = Array.from(new Set(data.locations));
          const machineTypes = Array.from(new Set(data.machine_type.map(item => item["Machine Type"])));          
          const errorCodes = Array.from(new Set(data.error_codes.map(item => item["Code"])));
          const problemTypes = Array.from(new Set(data.problem_type.map(item => item["Problem Type"])));

          // Set state variables with the extracted arrays
          setLocationList(locations);
          setMachineTypeList(machineTypes);
          setErrorCodeList(errorCodes);
          setProblemTypeList(problemTypes);

        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error - show a message or redirect to an error page
      }
    }

    fetchData();
  }, []);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
    ? []
    : locationList.filter((location) => location.toLowerCase().includes(inputValue));
  };


  const getSuggestionValue = (suggestion) => suggestion;

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const [suggestions, setSuggestions] = useState([]);

  const inputProps = {
    placeholder: 'Type a location (or select "Other Location")',
    value: location,
    onChange: (event, { newValue }) => {
      setLocation(newValue);
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Check if the email is valid before proceeding
    if (!emailRegex.test(email) && email !== '') {
      alert('Please enter a valid email address.');
      return;
    }

    // Check if the phone number is valid before proceeding
    if (!phoneRegex.test(phone) && phone !== '') {
      alert('Please enter a valid phone number in the format (___) ___-____.');
      return;
    }

    // Check if the phone number is valid before proceeding
    if (!phoneRegex.test(refundPhone) && refundPhone !== '') {
      alert('Please enter a valid phone number in the format (___) ___-____.');
      return;
    }

    // Check if the selected location is in the location list
    const locationExists = locationList.includes(location);
  
    if (locationExists) {
      const formData = {
        service_type: "General",
        location,
        locationDescription,
        machineType,
        machineId,
        name,
        firstName,
        lastName,
        address,
        phoneType,
        phone,
        refundPhone,
        email,
        errorCode,
        errorDescription,
        problemType,
        problemDescription,
        refundOption,
        refundAmount,
        lostMoneyMethod,
        serialNumber,
      };
  
      try {
        const response = await fetch('/api/gen-service', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        if (response.ok) {
          const responseData = await response.json();
          console.log(responseData); // Use responseData received from the server
          // Handle success, maybe show a success message
          setFormStatus('success');
        } else {
          throw new Error('Failed to submit the form');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        // Handle error, show an error message to the user
        setFormStatus('error');
      }
    } else {
      alert('Please select a valid location from the list.');
    }
  };
  

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="form-container">
          <h2 className="text-center" style={{ marginBottom: '0'}}>Submit Service Ticket</h2>
          <p className="text-center" style={{ marginBottom: '0', color: '#ccc' }}><small>*required field</small></p>
            {formStatus === 'success' ? (
              <SuccessComponent />
            ) : formStatus === 'error' ? (
              <ErrorComponent />
            ) : (
              <form onSubmit={handleSubmit}>
              <div className="form-group">
                  <label htmlFor="inputLocation">Location<span style={{ color: '#ccc' }}>*</span></label>
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                      ...inputProps,
                      required: true}} 
                    theme={{
                      container: 'autosuggest-container',
                      suggestionsContainer: 'autosuggest-suggestions-container',
                      suggestionsList: 'autosuggest-suggestions-list',
                      suggestion: 'autosuggest-suggestion',
                      input: 'form-control',
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="inputLocationDescription">Other Location (if applicable)</label>
                  <textarea
                    className="form-control"
                    id="inputLocationDescription"
                    rows="1"
                    placeholder="Enter other location"
                    value={locationDescription}
                    onChange={(e) => setLocationDescription(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="inputMachineType">Machine Type<span style={{ color: '#ccc' }}>*</span></label>
                  <select
                    className="form-control"
                    id="inputMachineType"
                    value={machineType}
                    onChange={(e) => setMachineType(e.target.value)}
                    required
                  >
                    <option value="">Select a machine type</option>
                    {machineTypeList.map((code, index) => (
                      <option key={index} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="inputMachineId">Machine ID<span style={{ color: '#ccc' }}>*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputMachineId"
                    placeholder="Enter machine ID / position (e.g. 1, 2, A, B)"
                    value={machineId}
                    onChange={(e) => setMachineId(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="inputName">Name<span style={{ color: '#ccc' }}>*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="Enter full name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required // Required field
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="inputPhone">Phone</label>
                  <InputMask
                    mask="(999) 999-9999"
                    className="form-control"
                    id="inputPhone"
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="inputErrorCode">Error Code Shown on Machine Screen
                    <span style={{ color: '#ccc' }}>*</span> (Out of Order _____)
                  </label>
                  <select
                    className="form-control"
                    id="inputErrorCode"
                    value={errorCode}
                    onChange={(e) => setErrorCode(e.target.value)}
                    required
                  >
                    <option value="">Select an error code</option>
                    {errorCodeList.map((code, index) => (
                      <option key={index} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="inputErrorDescription">Other Error Code Shown</label>
                  <textarea
                    className="form-control"
                    id="inputErrorDescription"
                    rows="1"
                    placeholder="Enter other error code"
                    value={errorDescription}
                    onChange={(e) => setErrorDescription(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="inputProblemType">Problem Type<span style={{ color: '#ccc' }}>*</span></label>
                  <select
                    className="form-control"
                    id="inputProblemType"
                    value={problemType}
                    onChange={(e) => setProblemType(e.target.value)}
                    required // Required field
                  >
                    <option value="">Select a problem type</option>
                    {problemTypeList.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="inputProblemDescription">Problem Description</label>
                  <textarea
                    className="form-control"
                    id="inputProblemDescription"
                    rows="3"
                    placeholder="Enter problem description"
                    value={problemDescription}
                    onChange={(e) => setProblemDescription(e.target.value)}
                  ></textarea>
                </div>
                <Refund
                  firstName={firstName} 
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  address={address}
                  setAddress={setAddress}
                  email={email}
                  setEmail={setEmail}
                  phoneType={phoneType}
                  setPhoneType={setPhoneType}
                  refundPhone={refundPhone}
                  setRefundPhone={setRefundPhone}
                  refundOption={refundOption}
                  setRefundOption={setRefundOption}
                  refundAmount={refundAmount}
                  setRefundAmount={setRefundAmount}
                  lostMoneyMethod={lostMoneyMethod}
                  setLostMoneyMethod={setLostMoneyMethod}
                  serialNumber={serialNumber}
                  setSerialNumber={setSerialNumber}
                />
                <button type="submit" className="btn btn-block">
                  Submit
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenService;
