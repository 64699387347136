import React from 'react';

const MachineInfo = ({ machine }) => {
  const { id, location, machine_type } = machine || {};

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="form-container">
            <h2 className="text-center">Machine Information</h2>
            <div className="machine-info">
              <p><strong>Machine ID:</strong> {id}</p>
              <p><strong>Location:</strong> {location}</p>
              <p><strong>Machine Type:</strong> {machine_type}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineInfo;