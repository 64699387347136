import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';

const MachineSearch = () => {
  const [selectedMachine, setSelectedMachine] = useState('');
  const [machineList, setMachineList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch machine list when the component mounts
    async function fetchMachineList() {
      try {
        const response = await fetch('/api/machine-list');
        if (response.ok) {
          const data = await response.json();
          setMachineList(data);
        } else {
          throw new Error('Failed to fetch machine list');
        }
      } catch (error) {
        console.error('Error fetching machine list:', error);
        // Handle error - show a message or redirect to an error page
      }
    }

    fetchMachineList();
  }, []);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : machineList.filter((machine) => machine.toLowerCase().includes(inputValue));
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedMachine !== '') {
      const machineExists = machineList.includes(selectedMachine);
  
      if (machineExists) {
        try {
          navigate(`/service-request?machine_id=${selectedMachine}`);
        } catch (error) {
          console.error('Error navigating to service route:', error);
        }
      } else {
        alert('Please select a valid machine ID from the list.');
      }
    } else {
      alert('Please select or type a machine ID.');
    }
  };
  

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="form-container">
            <h2 className="text-center">Submit Machine ID</h2>
            <p className="text-center">Please select the ID of the machine requiring service:</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="inputMachineId">Machine ID<span style={{ color: '#ccc' }}>*</span></label>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={{
                    placeholder: 'Type a machine ID',
                    value: selectedMachine,
                    onChange: (_, { newValue }) => setSelectedMachine(newValue),
                    required: true,
                    className: 'form-control',
                  }}
                  theme={{
                    container: 'autosuggest-container',
                    suggestionsContainer: 'autosuggest-suggestions-container',
                    suggestionsList: 'autosuggest-suggestions-list',
                    suggestion: 'autosuggest-suggestion',
                    input: 'form-control',
                  }}
                />
              </div>
              <button type="submit" className="btn btn-block">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineSearch;
