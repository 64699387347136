import React from 'react';

const SuccessComponent = () => {
  return (
    <div className="alert alert-success" role="alert">
      <h4 className="alert-heading">Success!</h4>
      <p>Thanks for submitting a ticket! Our staff has been notified, and a technician will be dispatched.</p>
      <hr />
    </div>
  );
};


const ErrorComponent = () => {
  return (
    <div className="alert alert-danger" role="alert">
      <h4 className="alert-heading">Oops! Something went wrong.</h4>
      <p>We encountered an error while processing your request. Please try again later.</p>
      <hr />
      <p className="mb-0">You can go back and try submitting the form again.</p>
    </div>
  );
};


export {SuccessComponent, ErrorComponent};


