import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import MachineInfo from '../components/MachineInfo'; 
import MachineService from '../components/service/MachineService';
import Header from '../components/Header';


const MachineServicePage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const machineId = params.get('machine_id');

  const [machineInfo, setMachineInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (machineId) {
      fetchMachineDetails(machineId);
    }
  }, [machineId]);

  const fetchMachineDetails = async (id) => {
    try {
      const response = await fetch(`api/machine-details?machine_id=${id}`);
      if (response.ok) {
        const data = await response.json();
        const formattedData = {
          id: data['TediMatts ID'],
          location: data['Customer Name'],
          machine_type: data['Type'],
        };
        setMachineInfo(formattedData);
        setLoading(false);
      } else {
        throw new Error('Failed to fetch machine details');
      }
    } catch (error) {
      console.error('Error fetching machine details:', error);
      // Handle error - show a message or redirect to an error page
    }
  };

  return (
    <div className="container">
      <Header/>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <MachineInfo machine={machineInfo || {}} />
          <MachineService />
        </div>
      </div>
    </div>
  );
};

export default MachineServicePage;
