import React from 'react';
import MachineSearch from '../components/MachineSearch'; 
import GenServiceButton from '../components/GenServiceLinks'; 
import Header from '../components/Header';

const LandingPage = () => {


  return (
    <div className="container">
          <Header />
          <MachineSearch />
          <GenServiceButton />
    </div>
  );
};

export default LandingPage;
