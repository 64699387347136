import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import GenService from '../components/service/GenService';
import Header from '../components/Header';


const GenServicePage = () => {
  return (
    <div className="container">
      <Header />
      <div className="row justify-content-center">
        <div className="col-md-12">
          <GenService />
        </div>
      </div>
    </div>
  );
};

export default GenServicePage;
