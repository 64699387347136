import React from 'react';

const Header = () => {

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          {/* <div className="form-container"> */}
            <div className="image-container">
              <img src="/logo192.png" alt="logo" className="centered-image" />
            </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Header;