// Refund.jsx

import React from 'react';
import InputMask from 'react-input-mask'

const Refund = ({
  firstName, 
  setFirstName,
  lastName, 
  setLastName,
  address,
  setAddress,
  email, 
  setEmail,
  phoneType, 
  setPhoneType,
  refundPhone, 
  setRefundPhone,
  refundOption,
  setRefundOption,
  refundAmount,
  setRefundAmount,
  lostMoneyMethod,
  setLostMoneyMethod,
  serialNumber,
  setSerialNumber,
}) => {
  return (
    <>
      <div className="form-group">
        <label className="refund-label">
          Refund<span style={{ color: '#ccc' }}>*</span>?
        </label>
        <div className="refund-radio-group">
          <label className="refund-radio-label">
            <input
              type="radio"
              value="no"
              checked={refundOption === 'no'}
              onChange={() => {
                setRefundOption('no');
                // Reset values to empty string when refund option is set to 'no'
                setRefundAmount('');
                setLostMoneyMethod('');
                setSerialNumber('');
              }}
            /> No
          </label>
          <label className="refund-radio-label">
            <input
              type="radio"
              value="yes"
              checked={refundOption === 'yes'}
              onChange={() => setRefundOption('yes')}
            /> Yes
          </label>
        </div>
      </div>

      {refundOption === 'yes' && (
        <>
          <div className="form-group">
            <label htmlFor="inputFirstName">First Name<span style={{ color: '#ccc' }}>*</span></label>
            <input
              type="text"
              className="form-control"
              id="inputFirstName"
              placeholder="Enter first name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required // Required field
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputLastName">Last Name<span style={{ color: '#ccc' }}>*</span></label>
            <input
              type="text"
              className="form-control"
              id="inputLastName"
              placeholder="Enter last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required // Required field
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputAddress">Address</label>
            <input
              type="text"
              className="form-control"
              id="inputAddress"
              placeholder="Enter Apt / Unit / Room #"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputEmail">Email<span style={{ color: '#ccc' }}>*</span></label>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required // Required field
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputPhoneType">Phone Type<span style={{ color: '#ccc' }}>*</span></label>
            <select
              className="form-control"
              id="inputPhoneType"
              value={phoneType}
              onChange={(e) => setPhoneType(e.target.value)}
              required // Required field
            >
              <option value="" disabled selected>Select phone type</option>
              <option value="mobile">Mobile</option>
              <option value="home">Home</option>
              <option value="work">Work</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="inputPhone">Phone<span style={{ color: '#ccc' }}>*</span></label>
            <InputMask
              mask="(999) 999-9999"
              className="form-control"
              id="inputPhone"
              placeholder="Enter phone number"
              value={refundPhone}
              onChange={(e) => setRefundPhone(e.target.value)}
              required // Required field
            />
          </div>

          <div className="form-group">
            <label htmlFor="refundAmount" className="refund-input-label">
              Refund Amount<span style={{ color: '#ccc' }}>*</span>
            </label>
            <input
              type="text"
              id="refundAmount"
              className="form-control refund-input"
              value={refundAmount}
              onChange={(e) => setRefundAmount(e.target.value.replace(/[^0-9.]/g, ''))}
              placeholder="$0.00"
              pattern="^\d+(\.\d{0,2})?$"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="lostMoneyMethod" className="refund-input-label">
              What Method Did You Lose Money?<span style={{ color: '#ccc' }}>*</span>
            </label>
            <select
              id="lostMoneyMethod"
              className="form-control refund-input"
              value={lostMoneyMethod}
              onChange={(e) => setLostMoneyMethod(e.target.value)}
              required
            >
              <option value="" disabled selected>Select Method</option>
              <option value="laundryCard">Laundry Card</option>
              <option value="mobileApp">Mobile App</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="serialNumber" className="refund-input-label">
              Laundry Card Serial Number
              <span style={{ color: '#ccc' }}>*</span> (For Mobile Apps, enter "N/A")
            </label>
            <input
              type="text"
              id="serialNumber"
              className="form-control refund-input"
              value={serialNumber}
              placeholder="Provide number on back of card"
              onChange={(e) => setSerialNumber(e.target.value)}
              required
            />
          </div>
        </>
      )}
    </>
  );
};

export default Refund;
