import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import LandingPage from './pages/LandingPage'
import MachineServicePage from './pages/MachineServicePage'
import GenServicePage from './pages/GenServicePage'
import OtherServicePage from './pages/OtherServicePage'

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/service-request" element={<MachineServicePage />} />
          <Route path="/service-request-general" element={<GenServicePage />} />            
          <Route path="/service-request-other" element={<OtherServicePage />} />
        </Routes>
      </div>
    </Router>
  );
};

// api/machine-details?machine_id=machine_06

export default App;

